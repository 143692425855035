<template>
  <b-modal
    id="modal-create-periodicity"
    ref="modal-create-periodicity"
    centered="centered"
    :title="(periodicity.id ? 'Modifier' : 'Ajouter') + ` une périodicité`"
    @ok="create"
  >
    <div v-if="isCreatingPeriodicity">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreatePeriodicity">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <ejs-textbox
                id="label"
                v-model="periodicity.label"
                name="codeCRO"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="d-flex mb-1">
          <b-form-group label="Unité *" label-for="duration" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="unit"
              rules="required"
            >
              <ejs-dropdownlist
                @select="affectDefaultValue"
                id="propertyType"
                class="w-100"
                popupHeight="300px"
                :dataSource="unitList"
                :fields="{ text: 'label', value: 'value' }"
                v-model="periodicity.unit"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="d-flex mb-1">
          <b-form-group label="Durée *" label-for="duration" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="duration"
              rules="required"
            >
              <div v-show="isUnitWithDuration">
                <ejs-dropdownlist
                  id="duration_dropdown"
                  class="w-100"
                  popupHeight="300px"
                  :dataSource="durationOfUnit"
                  v-model="periodicity.duration"
                />
              </div>
              <div v-show="isUnitWithoutDurationAndUnit">
                <ejs-textbox
                  id="duration_textbox"
                  v-model="periodicity.duration"
                  name="duration_textbox"
                  :readonly="periodicity.unit == 'day'"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="periodicity.id && !archive"
          variant="outline-danger"
          @click="archivePeriodicityLocal(periodicity.id, periodicity.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-else-if="periodicity.id && archive"
          variant="outline-warning"
          @click="restorePeriodicityLocal(periodicity.id, periodicity.label)"
          ><feather-icon icon="ArrowDownIcon"
        /></b-button>
        <b-button
          class="float-right"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="ok()"
          >{{ periodicity.id ? "Modifier" : "Ajouter" }}</b-button
        >
        <b-button
          class="float-right mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      periodicity: {
        label: null,
        unit: null,
        duration: null,
        workspaceId: this.$store.getters.workspaceSelected.id,
      },
      unitList: [
        {
          label: "Jour",
          value: "day",
          type: "number",
          default: 1,
        },
        {
          label: "Semaine",
          value: "week",
          type: "dropdown",
          duration: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26,
          ],
          default: null,
        },
        {
          label: "Mois",
          value: "month",
          type: "dropdown",
          duration: [1, 2, 3, 4, 6],
          default: null,
        },
        {
          label: "Trimestre",
          value: "quarter",
          type: "dropdown",
          duration: [1, 2, 4],
          default: null,
        },
        {
          label: "Année",
          value: "year",
          type: "number",
          default: 1,
        },
        // {
        //   label : 'Heure',
        //   value : 'hour'
        // },
        // {
        //   label : 'Minute',
        //   value : 'minute'
        // },
        // {
        //   label : 'Seconde',
        //   value : 'second'
        // },
        // {
        //   label : 'Milliseconde',
        //   value : 'millisecond'
        // }
      ],
    };
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupCreatePeriodicityEvent(id) {
      if (id > 0 && !this.archive) {
        this.getPeriodicityById({
          periodicityId: id,
        }).then((res) => {
          this.periodicity = res;
        });
      } else if (id > 0 && this.archive) {
        this.getPeriodicityArchivedById({
          periodicityId: id,
        }).then((res) => {
          this.periodicity = res;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.periodicity = {
        label: null,
        unit: null,
        duration: null,
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    closeModal() {
      this.$emit("closePopupPeriodicity", true);
      this.$bvModal.hide("modal-create-periodicity");
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreatePeriodicity.validate().then((success1) => {
        if (success1) {
          if (this.periodicity.id) {
            this.updatePeriodicity({ periodicity: this.periodicity }).then(
              (res) => {
                this.closeModal();
              }
            );
          } else {
            this.createPeriodicity({ periodicity: this.periodicity }).then(
              (res) => {
                this.closeModal();
              }
            );
          }
        }
      });
    },
    archivePeriodicityLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette périodicité en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver la périodicité "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archivePeriodicity({
              periodicityIds: [id],
            }).then((res) => {
              this.closeModal();
            });
          }
        });
    },
    restorePeriodicityLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser cette périodicité dans tous les modules de votre application",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer la périodicité "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restorePeriodicity({
              periodicityIds: [id],
            }).then((res) => {
              this.closeModal();
            });
          }
        });
    },
    affectDefaultValue(arg) {
      if (arg.itemData?.default) {
        this.periodicity.duration = arg.itemData.default;
      }
    },
    ...mapActions([
      "createPeriodicity",
      "updatePeriodicity",
      "getPeriodicityById",
      "archivePeriodicity",
      "restorePeriodicity",
      "getPeriodicityArchivedById",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingPeriodicity"]),
    isUnitWithDuration() {
      if (this.periodicity?.unit) {
        return this.unitList.find((el) => el.value == this.periodicity.unit)
          .duration
          ? true
          : false;
      } else {
        return false;
      }
    },
    isUnitWithoutDurationAndUnit() {
      if (this.periodicity?.unit != null) {
        return this.unitList.find((el) => el.value == this.periodicity.unit)
          .duration
          ? false
          : true;
      } else {
        return true;
      }
    },
    durationOfUnit() {
      if (this.periodicity?.unit) {
        return this.unitList.find((el) => el.value == this.periodicity.unit)
          .duration;
      } else {
        return [];
      }
    },
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
};
</script>
